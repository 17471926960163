.SubNavbar {
  margin: 0 1.5rem;
  display: flex;
  justify-content: space-between;
}
.SubNavbar p {
  padding: 0.625rem 1.25rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 0.875rem;
}
.ActionButton p {
  color: white;
  background: #143b68;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  cursor: pointer;
  position: relative;
}
.ActionButtonSvg {
  display: none;
}
/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .SubNavbar {
    margin: 0 4rem;
  }
  .ActionButtonSvg {
    display: block;
    width: 15rem;
    transform: rotate(180deg);
    position: absolute;
    top: -35rem;
    right: 10rem;
    right: 3rem;
  }
  .Footer {
    width: 100%;
    margin-top: 6rem;
    padding-right: 5rem;
    display: flex;
    justify-content: flex-end;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .SubNavbar {
    margin: 0 11rem;
  }
  .ActionButtonSvg {
    right: 10rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}
