@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "PT Sans", sans-serif;
  background: #f3f2f0;
}

@font-face {
  font-family: "Futura Extra Bold";
  src: local("Futura Extra Bold"),
    url(/static/media/Futura_Extra_Bold.db7c22c7.otf) format("truetype");
}

.control-panel {
  font-size: 16px;
  color: black;
  background-color: white;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  color: lightgray;
}

/* .App {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 3rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Login_Wrapper__1FkRp {
  height: 100vh;
  background-color: #e5e5e5;
  overflow: hidden;
  overflow-y: auto;
}
.Login_LoginContainer__3XHoe {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.Login_Login__3ocEz {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

.Login_ImgWrapper__2uxnE {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}
.Login_ImgWrapper__2uxnE img {
  width: 100%;
}

.Login_LoginTitle__2rPET {
  font-size: 2em;
  text-align: left;
  line-height: 1.2em;
  margin-bottom: 40px;
}
.Login_LoginTitle__2rPET span {
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}

/* ====FORM STYLING START====== */
.Login_InputGroup__2kFmk {
  position: relative;
  width: 80%;
  margin-bottom: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Login_Input__mYXUq {
  font-size: 18px;
  font-family: inherit;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  font-family: Arial, Helvetica, sans-serif;
}
.Login_Input__mYXUq:focus {
  outline: none;
}
.Login_Phone__1ZiMx {
  padding-left: 45px;
}
.Login_InputGroup__2kFmk i {
  position: absolute;
  font-size: 1.3em;
  top: 12px;
  left: -35px;
  color: #999;
  cursor: pointer;
}
.Login_MUIInputGroup__3bubw {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}
.Login_InputError__v2Fdc {
  font-size: 0.85rem;
  color: #e0004d;
  margin-top: 0.5em;
}
/* LABEL ======= */
.Login_InputLabel__1Rol6 {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */
.Login_Input__mYXUq:focus ~ .Login_InputLabel__1Rol6,
.Login_Input__mYXUq:valid ~ .Login_InputLabel__1Rol6 {
  top: -20px;
  font-size: 14px;
  color: #003c71;
}
/* BOTTOM BARS ========= */
.Login_Bar__36AOw {
  position: relative;
  display: block;
  width: 100%;
}
.Login_Bar__36AOw:before,
.Login_Bar__36AOw:after {
  content: "";
  height: 2px;
  width: 0px;
  bottom: 1px;
  position: absolute;
  background: #003c71;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.Login_Bar__36AOw:before {
  left: 50%;
}
.Login_Bar__36AOw:after {
  right: 50%;
}
/* active state */
.Login_Input__mYXUq:focus ~ .Login_Bar__36AOw:before,
.Login_Input__mYXUq:focus ~ .Login_Bar__36AOw:after {
  width: 50%;
}

/* HIGHLIGHTER ============ */
.Login_Highlight__27oIh {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.Login_Input__mYXUq:focus ~ .Login_Highlight__27oIh {
  animation: Login_inputHighlighter__DYwyX 0.3s ease;
  -webkit-animation: Login_inputHighlighter__DYwyX 0.3s ease;
  -moz-animation: Login_inputHighlighter__DYwyX 0.3s ease;
}
/* Animations */
@keyframes Login_inputHighlighter__DYwyX {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-webkit-keyframes Login_inputHighlighter__DYwyX {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}

/* Indicatif ====== */
.Login_Indicatif__2qDw2 {
  position: absolute;
  font-size: 18px;
  top: 24%;
  left: 0;
  opacity: 0;
  font-family: Arial, Helvetica, sans-serif;
}
/* active state */
.Login_Input__mYXUq:focus ~ .Login_Indicatif__2qDw2,
.Login_Input__mYXUq:valid ~ .Login_Indicatif__2qDw2 {
  opacity: 1;
}

/* ========= CGU CHECKBOX =========== */

.Login_CGUGroup__tlFQS input[type="checkbox"] {
  display: none;
}
.Login_CGUGroup__tlFQS input[type="checkbox"] + label:before {
  font-family: FontAwesome;
  display: inline-block;
}

.Login_CGUGroup__tlFQS input[type="checkbox"]:checked + label:before {
  content: "\f046";
}
.Login_CGUGroup__tlFQS input[type="checkbox"]:checked + label:before {
  letter-spacing: 8px;
}

.Login_CGUGroup__tlFQS input[type="checkbox"] + label:before {
  content: "\f096";
}
.Login_CGUGroup__tlFQS input[type="checkbox"] + label:before {
  letter-spacing: 10px;
}

/* CGU Link ====== */
.Login_CGUGroup__tlFQS label {
  color: #828489;
  font-size: 0.9em;
}
.Login_CGUGroup__tlFQS span {
  cursor: pointer;
  /* font-weight: 700; */
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}

/* ======SUBMIT BUTTON ====== */
.Login_SubmitButtonContainer__24b8z {
  margin-top: 40px;
}

/* Anon link */
.Login_AnonLogin__2y6fM {
  margin-top: 1em;
  text-align: center;
}

.RefInfo_RefInfoTitle__ITjNf {
  display: flex;
}
.RefInfo_RefInfoTitle__ITjNf h3 {
  margin-left: 8px;
}

.RefInfo_RefWrapper__GSAb0 img {
  width: 100%;
  margin-top: 15px;
}

.CGU_CGUTitle__1tGFL {
  text-align: center;
}

.CGU_CGUWrapper__3n2Zx {
  margin-top: 20px;
  height: 50vh;
  overflow-y: scroll;
}

.CGU_Article__2O10j {
  margin-bottom: 1em;
}

.CGU_ArticleTitle__15czw {
  margin-bottom: 0.5em;
}
.CGU_ArticleSubtitle__1JUjo {
  margin-bottom: 0.5em;
}
.CGU_ArticleContent__3b-nO {
  margin-bottom: 0.5em;
}
.CGU_ArticleContent__3b-nO p {
  margin-bottom: 0.3em;
}

.Modal_Modal__2PPqx {
  position: fixed;
  z-index: 500;
  background: white;
  border-radius: 9px;
  width: 90%;
  padding: 16px;
  left: 5%;
  top: 30%;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
  .Modal_Modal__2PPqx {
    width: 500px;
    left: calc(50% - 250px);
  }
}

.Backdrop_Backdrop__bW1XQ {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  /* background-color: rgba(229, 229, 229, 0.8); */
  background-color: rgba(0, 0, 0, 0.5);
}

.SubmitButton_SubmitButton__2rLCO {
  display: flex;
  margin: 0 auto;
  align-items: center;
  padding: 1em 1em;
  min-width: 10em;
  border: 0;
  outline: 0;
  font-size: 0.9rem;
  text-align: center;
  /* Primaire Rouge 1 */
  background: #e0004d;
  /* 6dp elevation */
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 6px 10px rgba(0, 0, 0, 0.14);
  border-radius: 7px;
  cursor: pointer;
  color: white;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.SubmitButton_SubmitButton__2rLCO:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.SubmitButton_SubmitButton__2rLCO:disabled {
  background-color: gray;
  color: #ccc;
  cursor: not-allowed;
}

/* Invalid state */
.SubmitButton_Invalid__2AP_g i {
  -webkit-transform: translate(-30vw, 11vh);
          transform: translate(-30vw, 11vh);
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 0;
}
.SubmitButton_Invalid__2AP_g span {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  /* transition: all linear 0.2s; */
}

/* Valid state */
.SubmitButton_Valid__3sFb1 i {
  color: #ffc300;
  font-size: 1.4em;
  margin-left: 0.4em;
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 1;
}

/* Loading state */
.SubmitButton_Clicked__XWlI8 i {
  -webkit-transform: translate(100vw, -90vh);
          transform: translate(100vw, -90vh);
  -webkit-transition: all linear 1.3s;
  transition: all linear 1.3s;
}

.CircularLoading_CircularLoading__3EICz {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.Home_SubNavbar__3d3He {
  margin: 0 1.5rem;
  display: flex;
  justify-content: space-between;
}
.Home_SubNavbar__3d3He p {
  padding: 0.625rem 1.25rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 0.875rem;
}
.Home_ActionButton__2RiW3 p {
  color: white;
  background: #143b68;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  cursor: pointer;
  position: relative;
}
.Home_ActionButtonSvg__3W1Ba {
  display: none;
}
/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Home_SubNavbar__3d3He {
    margin: 0 4rem;
  }
  .Home_ActionButtonSvg__3W1Ba {
    display: block;
    width: 15rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    position: absolute;
    top: -35rem;
    right: 10rem;
    right: 3rem;
  }
  .Home_Footer__3asWi {
    width: 100%;
    margin-top: 6rem;
    padding-right: 5rem;
    display: flex;
    justify-content: flex-end;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Home_SubNavbar__3d3He {
    margin: 0 11rem;
  }
  .Home_ActionButtonSvg__3W1Ba {
    right: 10rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.HomeToolbar_Header__2O-kR {
  background-color: #f3f2f0;
  padding: 2rem 1.5rem;
}

.HomeToolbar_Toolbar__R3gwM {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.HomeToolbar_Title__2FCeQ {
  margin-top: 1.5rem;
  font-family: "Nunito Sans";
  line-height: 1.7rem;
}
.HomeToolbar_Title__2FCeQ h3 {
  font-size: 1.8rem;
  color: #292f33;
  text-transform: uppercase;
  font-weight: 900;
}
.HomeToolbar_Title__2FCeQ h4 {
  font-size: 1.6rem;
  font-weight: 600;
  color: #5b5855;
}

.HomeToolbar_Description__1o12a {
  color: #5b5855;
  margin-top: 2rem;
  font-weight: 400;
  font-size: 1.125rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .HomeToolbar_Header__2O-kR {
    padding: 2rem 4rem;
  }
  .HomeToolbar_Title__2FCeQ h3 {
    font-size: 1.8rem;
  }
  .HomeToolbar_Title__2FCeQ {
    display: flex;
  }
  .HomeToolbar_Title__2FCeQ h4 {
    margin-left: 1rem;
  }
  .HomeToolbar_Description__1o12a {
    width: 23rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .HomeToolbar_Header__2O-kR {
    padding: 2rem 11rem;
  }

  .HomeToolbar_Title__2FCeQ h3 {
    font-size: 2rem;
  }
}

.HomeFooter_Footer__NJECM {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.HomeFooter_LogoContainer__1MnRE {
  width: 15em;
  margin: 1em 0;
}
.HomeFooter_LogoContainer__1MnRE img {
  width: 100%;
}
.HomeFooter_LegalLinks__15ihi {
  display: flex;
  margin-top: 1em;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.5rem;
}
.HomeFooter_LegalLinks__15ihi p {
  cursor: pointer;
  margin: 0.5rem;
  font-size: 0.8rem;
  color: #828282;
  padding: 0.8rem 1rem;
  background-color: #f3f2f0;
  border-radius: 10px;
}
.HomeFooter_Copyrights__13O7s {
  margin: 1em 0;
  color: #5b5855;
  text-align: center;
  font-size: 0.8rem;
}
.HomeFooter_Copyrights__13O7s span {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 900;
  font-family: "Nunito Sans", sans-serif;
}

.HomeFooter_ImmobiliereLogo__2L2yq {
  width: 12rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.ViewSelector_ViewSelector__2lh4k {
  display: flex;
  justify-content: space-between;
}
.ViewSelector_ViewSelector__2lh4k p {
  border-radius: 30px;
  cursor: pointer;
  box-sizing: border-box;
}
.ViewSelector_UnselectedView__1GMZd {
  color: #292f33;
  background: #f3f2f0;
  border: 1px solid #143b68;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
}
.ViewSelector_SelectedView__3jTQc {
  background: #143b68;
  color: white;
  border: 1px solid #143b68;
}
/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ViewSelector_ViewSelector__2lh4k {
    width: 15rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarDesktop_Calendar__35ZX0 {
  margin: 4rem 4rem;
}

.CalendarDesktop_CalendarTitle__c_88_ {
  font-size: 1.25rem;
  color: #292f33;
}
.CalendarDesktop_CalendarTitle__c_88_ span {
  font-weight: 700;
}

.CalendarDesktop_Main__1KKi9 {
  display: flex;
  margin-top: 2rem;
}

.CalendarDesktop_CardsList__2mhq1 {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.CalendarDesktop_Modal__VnlVH {
  min-width: 21rem;
  max-width: 21rem;
}
.CalendarDesktop_PlanSchematique__Zwx5d {
  /* background-color: white; */
  /* border-radius: 15px; */
  width: 70%;
  margin: 0 auto;
  margin-top: 2rem;
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .CalendarDesktop_Calendar__35ZX0 {
    margin: 4rem 11rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarCard_Card__3MNdp {
  max-width: 15rem;
  min-width: 15rem;
  max-height: 10rem;
  padding: 1rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.CalendarCard_Emoji__1XWXy {
  font-size: 2rem;
  margin: auto;
}
.CalendarCard_Card__3MNdp p {
  margin-top: 0.5rem;
  font-size: 1rem;
  text-align: center;
}
.CalendarCard_SelectedCard__VkVmt {
  background: #143b68;
  border-radius: 18px;
  color: white;
  max-width: 15rem;
  min-width: 15rem;
  max-height: 10rem;
  padding: 1rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.CalendarCard_SelectedCard__VkVmt p {
  margin-top: 0.5rem;
  font-size: 1rem;
  text-align: center;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarModal_CalendarModal__3_-eG {
  background: #ffffff;
  border-radius: 18px;
  height: 30rem;
  overflow-y: scroll;
  /*-ms-overflow-style: none;  IE and Edge */
  /*scrollbar-width: none;  Firefox */
}
/* .CalendarModal::-webkit-scrollbar {
  display: none;
} */

.CalendarModal_StepTitle__1-a2I {
  background-color: #143b68;
  color: white;
  border-radius: 18px 18px 0 0;
  display: flex;
  padding: 1rem;
  font-size: 1.18rem;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
}
.CalendarModal_TitleEmoji__1Xn3i {
  font-size: 3rem;
  margin-right: 1rem;
  margin-top: -0.5rem;
}
.CalendarModal_Substeps__3Vq-7 {
  padding: 1rem 1rem;
}
.CalendarModal_Substep__3OOrP {
  color: #292f33;
  font-size: 1rem;
  margin-bottom: 2rem;
}
.CalendarModal_Description__3Giam {
  display: flex;
  margin-bottom: 0.3rem;
}
.CalendarModal_Emoji__2nUrs {
  font-size: 1.5rem;
  margin-right: auto;
  margin-left: auto;
}

.CalendarModal_Plan__3ZE0y {
  margin-bottom: 2rem;
  text-align: center;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarMobile_Calendar__2JOSt {
  margin: 4rem 4rem;
}

.CalendarMobile_CalendarTitle__3jjXV {
  font-size: 1.25rem;
  color: #143b68;
}
.CalendarMobile_CalendarTitle__3jjXV span {
  font-weight: 700;
}

.CalendarMobile_Main__1UlPc {
  display: flex;
  margin-top: 2rem;
}

.CalendarMobile_CardsList__21XWn {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.CalendarMobile_Modal__3kVkN {
  width: 80%;
  outline: none;
}
.CalendarMobile_PlanSchematique__EIn2E {
  width: 100%;
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .CalendarMobile_Calendar__2JOSt {
    margin: 4rem 11rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.Projet_Projet___ztiq {
  padding: 2rem 1.5rem;
  margin-bottom: 2rem;
}
.Projet_Projet___ztiq p {
  margin-bottom: 1.5rem;
  color: #5b5855;
}

.Projet_Articles__3hysy {
  margin-bottom: 1.5rem;
}
.Projet_ArticlesHeader__1IIMz {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Projet_Description__55McH p {
  text-align: justify;
}

.Projet_Description__55McH ul {
  margin-bottom: 1.5rem;
  margin-top: -0.5rem;
  margin-left: 2rem;
}
.Projet_Description__55McH ul li {
  margin-bottom: 0.4rem;
  color: #5b5855;
  font-weight: bold;
}

.Projet_ActionButton__aEcI4 {
  display: none;
}
.Projet_ActionButton__aEcI4 p,
.Projet_ActionButtonOutside__P6Oda p {
  padding: 0.625rem 1.25rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 0.875rem;
  color: white;
  background: #143b68;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  margin-top: 4rem;
}
.Projet_ActionButtonSvg__25KTo {
  display: none;
}

.Projet_DescriptionSvg__sMIO6 {
  max-width: 20rem;
  margin: 0 auto;
}

.Projet_VisiteVirtuelle__10Jev {
  text-align: center;
  margin-top: -2rem;
  margin-bottom: 2rem;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Projet_Projet___ztiq {
    display: flex;
    flex-flow: row;
    padding: 4rem 4rem;
  }
  .Projet_Description__55McH {
    margin-right: 5rem;
    padding-top: 3rem;
  }
  .Projet_DescriptionSvg__sMIO6 {
    display: none;
  }
  .Projet_DescriptionSection__Sw7yn {
    position: relative;
  }
  .Projet_Description__55McH {
    /* margin-left: 13vw; */
    margin-right: 5vw;
  }

  .Projet_ActionButton__aEcI4 {
    display: none;
  }

  .Projet_BackgroundShape__39Xa0 {
    position: absolute;
    width: 37vw;
    height: 37vw;
    border-radius: 65% 37% 35% 64% / 58% 55% 37% 41%;
    background-color: #e3e7f1;
    /* background-color: lightcoral; */
    z-index: -2;
    right: -4rem;
    top: -4rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Projet_Projet___ztiq {
    padding: 4rem 11rem;
    padding-right: 5rem;
  }

  .Projet_DescriptionSection__Sw7yn {
    position: relative;
  }
  .Projet_Description__55McH {
    margin-left: 13vw;
    margin-right: 5vw;
  }
  .Projet_DescriptionSvg__sMIO6 {
    display: block;
    width: 25vw;
    position: absolute;
    left: -11rem;
    top: -3rem;
    z-index: -1;
  }

  .Projet_ActionButton__aEcI4 {
    display: block;
    position: relative;
  }

  .Projet_ActionButtonSvg__25KTo {
    display: block;
    width: 15rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
  }
  .Projet_ActionButtonSvg__25KTo::before {
    content: "";
    -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .Projet_BackgroundShape__39Xa0 {
    position: absolute;
    width: 37vw;
    height: 37vw;
    border-radius: 65% 37% 35% 64% / 58% 55% 37% 41%;
    background-color: #e3e7f1;
    /* background-color: lightcoral; */
    z-index: -2;
    right: -4rem;
    top: -4rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.ArticleModal_ArticleModal__AKaUk {
  padding: 0;
  border-radius: 10px;

  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ArticleModal_ArticleModal__AKaUk::-webkit-scrollbar {
  display: none;
}

.ArticleModal_ModalContent__1UWG9 {
  width: 80%;
  outline: none;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ArticleModal_ModalContent__1UWG9 {
    width: 50%;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .ArticleModal_ModalContent__1UWG9 {
    width: 40%;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.Articles_ArticleCard__wAZVI {
  background: #ffffff;
  padding: 1rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: 22rem;
}
.Articles_ArticleCard__wAZVI p {
  margin: 0;
  color: #292f33;
  font-size: 1rem;
}
.Articles_Emoji__Im3G7 {
  margin-right: 0.5rem;
  font-size: 1.8rem;
}
.Articles_DeleteButton__1VI_k {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Articles_ArticleCard__wAZVI {
    min-width: 23rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Articles_ArticleCard__wAZVI {
    min-width: 20rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.UploadDocument_AddButton__32-WD p {
  background: #ee9834;
  color: white;
  border: 1px solid #ee9834;
  cursor: pointer;
  padding: 0.625rem 1.25rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 0.875rem;
}
.UploadDocument_ModalContent__2SZQS {
  background-color: #f3f2f0;
  padding: 2rem;
  border-radius: 10px;
  width: 30rem;
  outline: none;
}
.UploadDocument_QuestionTitle__2C6yU {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.UploadDocument_QuestionTitle__2C6yU p {
  color: #2a2f39;
  margin-left: 0.4em;
}
.UploadDocument_TitleField__FXcaG {
  margin-top: 1rem;
}
.UploadDocument_CloudIcon__1h8ub {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.6rem;
  justify-content: center;
}
.UploadDocument_Buttons__d90oa {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .UploadDocument_ModalContent__2SZQS {
    width: 80%;
    outline: none;
  }
}

.ProfileSetup_Wrapper__2enXU {
  height: 100vh;
  background-color: #e5e5e5;
  overflow: hidden;
  overflow-y: auto;
}
.ProfileSetup_ProfileSetupContainer__1e3fR {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.ProfileSetup_ProfileSetup__1Hd7k {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

.ProfileSetup_ImgWrapper__2M2gB {
  width: 60%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.ProfileSetup_ImgWrapper__2M2gB img {
  width: 100%;
}

.ProfileSetup_Title__31cy2 {
  font-size: 1.5em;
  text-align: left;
  line-height: 1.2em;
  margin-bottom: 10px;
}
.ProfileSetup_Title__31cy2 span {
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}
.ProfileSetup_CircularProgress__1p8GP {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.ProfileSetup_QuestionContainer__2kRkc {
  height: 15rem;
  margin-bottom: 3.5rem;
}

/* START =========== Buttons */
.ProfileSetup_ButtonsContainer__2wyTo {
  position: absolute;
  left: 0;
  bottom: 2rem;
  right: 0;
  text-align: center;
}
.ProfileSetup_Button__28dC4 {
  display: inline-block;
  margin: 0 1rem;
}
.ProfileSetup_SubmitButton__6sLAu {
  line-height: 0;
  width: auto;
  padding: 0 12px;
  height: 48px;
  margin: 0 8px;
  font-size: 1.2rem;

  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 10px;

  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.NextButton_NextButton__C7_0M {
  line-height: 0;
  width: 58px;
  height: 58px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #777;
  background: #3d9ec73f;
  border: none;
  border-radius: 50px;
}

.NextButton_NextButton__C7_0M:hover {
  background-color: #3d9dc7;
  color: white;
}

.NextButton_NextButton__C7_0M:active {
  background-color: #3d9dc7;
  color: white;
  box-shadow: inset 1px 1px 2px #80012d, inset -1px -1px 2px #d6929850;
}
.NextButton_NextButton__C7_0M i {
  padding-left: 2px;
}

.BackButton_BackButton__372Kv {
  line-height: 0;
  width: 58px;
  height: 58px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #777;
  background: #3d9ec73f;
  border: none;
  border-radius: 50px;
}

.BackButton_BackButton__372Kv:hover {
  background-color: #3d9dc7;
  color: white;
}

.BackButton_BackButton__372Kv:active {
  background-color: #3d9dc7;
  color: white;
  box-shadow: inset 1px 1px 2px #80012d, inset -1px -1px 2px #d6929850;
}
.BackButton_BackButton__372Kv i {
  padding-right: 2px;
}

.CircularProgressBar_Circle__2fvsf {
  position: relative;
  width: 50px;
  height: 50px;
  border: 4px solid #c9d100;
  box-sizing: border-box;
  border-radius: 50%;
  /* animation-name: progress;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.CircularProgressBar_Circle__2fvsf::after {
  content: "";
  position: absolute;
  top: -21px;
  left: -21px;
  width: 180px;
  height: 180px;
  border: 22px solid #fff;
  box-sizing: border-box;
  border-radius: 50%;
  -webkit-clip-path: polygon(50% 50%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0);
          clip-path: polygon(50% 50%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0);
  /* animation-name: progress_after;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.CircularProgressBar_Etape__3jGXa {
  margin-top: 0.35em;
  font-weight: bold;
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 1.5em;
}

/* SART ------------Basic Input--------------- */
.Input_InputGroup__1d3B_ {
  position: relative;
  width: 80%;
  margin-bottom: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Input_Input__3jGUH {
  font-size: 18px;
  font-family: inherit;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  font-family: Arial, Helvetica, sans-serif;
}
.Input_Input__3jGUH:focus {
  outline: none;
}
.Input_Phone__dzl1f {
  padding-left: 35px;
}
.Input_InputGroup__1d3B_ i {
  position: absolute;
  font-size: 1.3em;
  top: 12px;
  left: -35px;
  color: #999;
  cursor: pointer;
}
/* LABEL ======= */
.Input_InputLabel__2oZs1 {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */
.Input_Input__3jGUH:focus ~ .Input_InputLabel__2oZs1,
.Input_Input__3jGUH:valid ~ .Input_InputLabel__2oZs1 {
  top: -20px;
  font-size: 14px;
  color: #e31869;
}
/* BOTTOM BARS ========= */
.Input_Bar__h55AO {
  position: relative;
  display: block;
  width: 100%;
}
.Input_Bar__h55AO:before,
.Input_Bar__h55AO:after {
  content: "";
  height: 2px;
  width: 0px;
  bottom: 1px;
  position: absolute;
  background: #e31869;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.Input_Bar__h55AO:before {
  left: 50%;
}
.Input_Bar__h55AO:after {
  right: 50%;
}
/* active state */
.Input_Input__3jGUH:focus ~ .Input_Bar__h55AO:before,
.Input_Input__3jGUH:focus ~ .Input_Bar__h55AO:after {
  width: 50%;
}

/* HIGHLIGHTER ============ */
.Input_Highlight__1haoR {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.Input_Input__3jGUH:focus ~ .Input_Highlight__1haoR {
  animation: Input_inputHighlighter__cShhT 0.3s ease;
  -webkit-animation: Input_inputHighlighter__cShhT 0.3s ease;
  -moz-animation: Input_inputHighlighter__cShhT 0.3s ease;
}
/* Animations */
@keyframes Input_inputHighlighter__cShhT {
  from {
    background: #e31869;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-webkit-keyframes Input_inputHighlighter__cShhT {
  from {
    background: #e31869;
  }
  to {
    width: 0;
    background: transparent;
  }
}

/* Indicatif ====== */
.Input_Indicatif__18c1D {
  position: absolute;
  font-size: 18px;
  top: 24%;
  left: 0;
  opacity: 0;
  font-family: Arial, Helvetica, sans-serif;
}
/* active state */
.Input_Input__3jGUH:focus ~ .Input_Indicatif__18c1D,
.Input_Input__3jGUH:valid ~ .Input_Indicatif__18c1D {
  opacity: 1;
}

/* START -----------Radio Button----------- */

.Input_RadioFormElement__3-VaE {
  /* margin-top: 10px; */
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.Input_RadioFormElement__3-VaE::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.Input_RadioDivElement__JK1DX {
  flex: 0 0 auto;
  margin: 20px 0;
  margin-right: 20px;
  width: 60px;
  height: 60px;
  position: relative;
}
.Input_RadioDivElement__JK1DX:first-child {
  margin-left: 10vw;
}

.Input_RadioDivElement__JK1DX input {
  opacity: 0;
}

.Input_RadioDivElement__JK1DX label {
  width: 100%;
  height: 100%;
  font-size: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1em;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  /* OmbreOUT */
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 10px;
  cursor: pointer;
}
.Input_RadioDivElement__JK1DX span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.Input_RadioDivElement__JK1DX input[type="radio"]:checked + label {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
  color: #e31869;
  font-weight: bold;
}

/* END -----------Radio Button----------- */

/* Small tablets */

.QuestionTwo_QuestionTwo__Hzgsy {
  margin: 1.3em 0;
  text-align: center;
}
.QuestionTwo_QuestionTwo__Hzgsy p {
  margin: 20px;
  font-size: 1.1rem;
}

.Radio_FormTitle__3877A {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.1rem;
  text-align: center;
}

.Radio_Form__2K9NB {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Radio_FormElement__1QML7 {
  margin: 5px 5px;
  width: 3.2em;
  height: 3.2em;
  position: relative;
}

.Radio_FormElement__1QML7 input[type="radio"] {
  display: none;
}

.Radio_FormElement__1QML7 label {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 15%;
  border: none;
  background-color: #3d9ec756;
  color: #777;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Radio_FormElement__1QML7 input[type="radio"]:checked + label {
  background-color: #3d9dc7;
  color: white;
}

/* .Travaux {
  overflow-x: hidden;
} */
.Travaux_SubHeader__3aTJv {
  background-color: #e5094d;
  padding: 1rem 0;
  margin-top: -0.1rem;
}
.Travaux_HomeLink__wu4Wg {
  color: white;
  display: flex;
  justify-content: center;
}

.Travaux_HomeLink__wu4Wg a {
  color: white;
  font-size: 0.9rem;
  margin-left: 0.4rem;
  text-decoration: underline;
}
.Travaux_Main__3tgdW {
  padding: 0 0.2em;
  margin-bottom: 1rem;
}
.Travaux_CardListTitle__2rnQA {
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Travaux_CardListTitle__2rnQA p {
  font-size: 1.2rem;
  text-align: center;
  color: #828489;
  margin-bottom: 1rem;
}
.Travaux_CardListTitle__2rnQA span {
  font-size: 1.2rem;
  font-weight: bold;
  color: #292f33;
}
.Travaux_Travaux__2lbii {
  /* margin-bottom: 4em; */
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Travaux_SubHeader__3aTJv {
    padding: 1rem 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Travaux_CardListTitle__2rnQA {
    flex-direction: row;
    justify-content: space-between;
  }
  .Travaux_CardListTitle__2rnQA p {
    font-size: 1.4rem;
  }
  .Travaux_CardListTitle__2rnQA span {
    font-size: 1.4rem;
  }
  .Travaux_Travaux__2lbii {
    margin: 0 4rem;
    margin-top: 1em;
    /* margin-bottom: 4em; */
  }
  .Travaux_MainCards__gOroX {
    display: flex;
    position: relative;
    min-height: 20em;
  }
  /* .NoCard {
    background-color: #f3f2f08a;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
    position: absolute;
    height: 30em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 20em;
  } */
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Travaux_SubHeader__3aTJv {
    padding: 1rem 11rem;
  }
  .Travaux_Travaux__2lbii {
    margin: 0 11em;
    /* margin-bottom: 4em; */
  }
}

.Toolbar_Header__2qDo6 {
  background-color: #143b68;
  padding: 2rem 1.5rem;
}

.Toolbar_Toolbar__1WCmV {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Toolbar_Title__3gUZ7 {
  margin-top: 1.5rem;
  color: white;
  font-family: "Nunito Sans";
  line-height: 1.7rem;
}
.Toolbar_Title__3gUZ7 h3 {
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 900;
}
.Toolbar_Title__3gUZ7 h4 {
  font-size: 1.6rem;
  font-weight: 600;
}

.Toolbar_Description__2s66g {
  color: white;
  margin-top: 2rem;
  font-weight: 400;
  font-size: 1.125rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Toolbar_Header__2qDo6 {
    padding: 4rem 4rem;
  }
  .Toolbar_Title__3gUZ7 h3 {
    font-size: 1.8rem;
  }
  .Toolbar_Title__3gUZ7 {
    display: flex;
  }
  .Toolbar_Title__3gUZ7 h4 {
    margin-left: 1rem;
  }
  .Toolbar_Description__2s66g {
    width: 23rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Toolbar_Header__2qDo6 {
    padding: 2rem 11rem;
  }

  .Toolbar_Title__3gUZ7 h3 {
    font-size: 2rem;
  }
}

.Filter_Filter__1u5uh {
}
.Filter_Filter__1u5uh p {
  font-size: 0.9rem;
}
.Filter_Filter__1u5uh span {
  font-size: 1.5rem;
  font-weight: bold;
}

.Filter_Categories__29mtg form {
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 1rem 0;
  /* flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; 
  scrollbar-width: none;  */
}
/* .Categories form::-webkit-scrollbar {
  display: none; 
} */

.Filter_Personnalisable__2GQ7n {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Filter_Personnalisable__2GQ7n label {
  background: #bcbecc;
  color: #5b5855;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Filter_Personnalisable__2GQ7n input[type="checkbox"] {
  display: none;
}

.Filter_Personnalisable__2GQ7n input[type="checkbox"]:checked + label {
  background: white;
  color: #5b5855;
}

/* =========================== */
.Filter_Informative__3UEZh {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Filter_Informative__3UEZh label {
  background: #bcbecc;
  color: #5b5855;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Filter_Informative__3UEZh input[type="checkbox"] {
  display: none;
}

.Filter_Informative__3UEZh input[type="checkbox"]:checked + label {
  background: white;
  color: #5b5855;
}

/* .CardList {
  margin-bottom: 2em;
}

.List {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

/* large tablets & laptops */
/* @media screen and (min-width: 768px) {
  .List {
    align-items: flex-start;
    width: 21em;
  }
} */

.CardList_CardList__kmgIO {
  margin-bottom: 2em;
}

.CardList_List__1sHZT {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  /* .List {
    align-items: flex-start;
    width: 21em;
  } */
}

.Card_Card__Dehvg {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.Card_SelectedCard__1KVVQ {
  border: 2px solid #e57373;
}

.Card_Thumbnail__1yust {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  display: flex;
}
.Card_Thumbnail__1yust img {
  border-radius: 14px;
  width: 100%;
}

.Card_Main__l5iED {
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Card_Titre__1GKIy {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.Card_Titre__1GKIy span {
  font-weight: 700;
}

.Card_Description__3dhkW {
  margin-right: 4em;
  font-size: 0.6rem;
}

.Card_Icon__2eFjf {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.Card_TagsContainer__3xrG1 {
  display: flex;
}
.Card_TagContainer__9TeIH {
  font-size: 0.6rem;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 1rem;
}
.Card_Tag__1pcms {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.Card_Tag__1pcms p {
  padding: 0 0.2rem;
}

.Card_Notification__2Rueq {
  position: absolute;
  top: -0.8rem;
  left: 1rem;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 1.6rem;
  background-color: #38bb6e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CardCreateReclamation_Card__2CgR3 {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;

  border: 3px solid #3b4578;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.CardCreateReclamation_SelectedCard__2E4k6 {
  border: 2px solid #e57373;
}

.CardCreateReclamation_Thumbnail__bkj-_ {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.CardCreateReclamation_Thumbnail__bkj-_ img {
  border-radius: 14px;
  width: 100%;
}

.CardCreateReclamation_Main__2g_ux {
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CardCreateReclamation_Titre__3uWpK {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.CardCreateReclamation_Titre__3uWpK span {
  font-weight: 700;
}

.CardCreateReclamation_Description__3b3Rm {
  margin-right: 4em;
  font-size: 0.6rem;
}

.CardCreateReclamation_Icon__2fAi4 {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.CardCreateReclamation_TagContainer__3GOtF {
  font-size: 0.6rem;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.CardCreateReclamation_Tag__3REFO {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.CardCreateReclamation_Tag__3REFO p {
  padding: 0 0.2rem;
}

.ModalCreateReclamation_ModalCreateReclamation__2BJj6 {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ModalCreateReclamation_ModalCreateReclamation__2BJj6::-webkit-scrollbar {
  display: none;
}

.ModalCreateReclamation_ModalContent__3zKvX {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: #f3f2f0;
  padding: 2rem;
}

.ModalCreateReclamation_ModalTitle__2t4A5 {
  margin-top: 1.4rem;
  margin-bottom: 1.7rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #3b4578;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ModalCreateReclamation_ModalContent__3zKvX {
    position: relative;
    border-radius: 10px;
    width: 30rem;
    height: 90%;
    max-height: 50rem;
    outline: none;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.ReclamationContent_IntroImage__2GQMA {
  margin: auto;
  margin-top: 4rem;
}

.ReclamationContent_Buttons__1GvWV {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  left: 2rem;
  display: flex;
  justify-content: space-between;
}

.ReclamationContent_QuestionTitle__3Dpub {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.ReclamationContent_QuestionTitle__3Dpub p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.ReclamationContent_RadioFormTwo__3PQJs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: space-between;
}

.ReclamationContent_RadioElementTwo__1eye3 {
  margin-top: 1rem;
  /*flex: 1 0 32%; */
}

.ReclamationContent_RadioElementTwo__1eye3 input[type="radio"] {
  display: none;
}

.ReclamationContent_RadioElementTwo__1eye3 input[type="radio"] + label + p {
  text-align: left;
  font-size: 0.8rem;
  width: 5rem;
}

.ReclamationContent_RadioElementTwo__1eye3 input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 5rem;
  width: 5rem;
  display: inline-block;
  position: relative;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.ReclamationContent_RadioElementTwo__1eye3 input[type="radio"] + label:hover {
  cursor: pointer;
}

.ReclamationContent_RadioElementTwo__1eye3 input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.ReclamationContent_RadioElementTwo__1eye3 input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.ReclamationContent_RadioElementTwo__1eye3 input[type="radio"]:checked + label i {
  display: inline-block;
}
.ReclamationContent_RadioElementTwo__1eye3 input[type="radio"]:checked + label + p {
  font-weight: bold;
}

.ReclamationContent_TitleField__1Q780 {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionField__XB-WL {
  margin-top: 1rem;
}

.ReclamationContent_ImagesRow__1FbFH {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.ReclamationContent_ImagePlaceholder__2bOXg {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReclamationContent_ImageUploaded__2dk1H {
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ReclamationContent_RecapTags__3aByG {
  display: flex;
}
.ReclamationContent_RecapTags__3aByG p {
  padding: 0.5rem;
  background-color: #ee9834;
  border-radius: 16px;
  margin-right: 1rem;
  color: white;
  font-size: 0.8rem;
}
.ReclamationContent_RecapTitle__kwBQd {
  margin-top: 1rem;
}
.ReclamationContent_TitleArea__2zn7o {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 2.5rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_TitleArea__2zn7o::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapDescription__17_2N {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionArea__2yqra {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 7rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_DescriptionArea__2yqra::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapImagesRow__cOS_2 {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

/* large tablets & laptops */
@media screen and (min-width: 401px) {
  .ReclamationContent_RadioElementTwo__1eye3 {
    margin-top: 1rem;
    padding-left: 2rem;
    flex: 1 0 32%;
  }
}

.CardUserReclamation_Card__3auba {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  border: 3px solid #3b4578;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
}
.CardUserReclamation_SelectedCard__CYe2Q {
  border: 2px solid #e57373;
}

.CardUserReclamation_Thumbnail__1eLl8 {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.CardUserReclamation_Thumbnail__1eLl8 img {
  border-radius: 14px;
  width: 100%;
}

.CardUserReclamation_Main__bDTqd {
  margin: 0.5em 0;
  min-width: 11rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CardUserReclamation_Titre__3XXEY {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.CardUserReclamation_Titre__3XXEY span {
  font-weight: 700;
}

.CardUserReclamation_Description__15xrx {
  margin-right: 4em;
  font-size: 0.6rem;
}

.CardUserReclamation_Icon__2lWKB {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.CardUserReclamation_TagContainer__1uf6b {
  font-size: 0.6rem;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.CardUserReclamation_Tag__SxBoF {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.CardUserReclamation_Tag__SxBoF p {
  padding: 0 0.2rem;
}

.CardUserReclamation_Notification__asF3X {
  position: absolute;
  top: -1.2rem;
  left: 1rem;
  padding: 0.3rem;
  border-radius: 100px;
  background-color: #fcb34a;
}

.ModalUserReclamation_ModalCreateReclamation__3VlpG {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ModalUserReclamation_ModalCreateReclamation__3VlpG::-webkit-scrollbar {
  display: none;
}

.ModalUserReclamation_ModalContent__3N39n {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: #f3f2f0;
  padding: 2rem;
}

.ModalUserReclamation_ModalTitle__1aq7- {
  margin-top: 1.4rem;
  margin-bottom: 1.7rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #3b4578;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ModalUserReclamation_ModalContent__3N39n {
    position: relative;
    border-radius: 10px;
    width: 30rem;
    height: 90%;
    max-height: 50rem;
    outline: none;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.ReclamationContent_IntroImage__1Gbql {
  margin: auto;
  margin-top: 4rem;
}

.ReclamationContent_Buttons__2uJdV {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  left: 2rem;
  display: flex;
  justify-content: space-between;
}

.ReclamationContent_QuestionTitle__221JK {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.ReclamationContent_QuestionTitle__221JK p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.ReclamationContent_RadioFormTwo__SXCqt {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: space-between;
}

.ReclamationContent_RadioElementTwo__3VaT9 {
  margin-top: 1rem;
  /*flex: 1 0 32%; */
}

.ReclamationContent_RadioElementTwo__3VaT9 input[type="radio"] {
  display: none;
}

.ReclamationContent_RadioElementTwo__3VaT9 input[type="radio"] + label + p {
  text-align: left;
  font-size: 0.8rem;
  width: 5rem;
}

.ReclamationContent_RadioElementTwo__3VaT9 input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 5rem;
  width: 5rem;
  display: inline-block;
  position: relative;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.ReclamationContent_RadioElementTwo__3VaT9 input[type="radio"] + label:hover {
  cursor: pointer;
}

.ReclamationContent_RadioElementTwo__3VaT9 input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.ReclamationContent_RadioElementTwo__3VaT9 input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.ReclamationContent_RadioElementTwo__3VaT9 input[type="radio"]:checked + label i {
  display: inline-block;
}
.ReclamationContent_RadioElementTwo__3VaT9 input[type="radio"]:checked + label + p {
  font-weight: bold;
}

.ReclamationContent_TitleField__2PQBK {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionField__j1CvT {
  margin-top: 1rem;
}

.ReclamationContent_ImagesRow__7S_Hs {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.ReclamationContent_ImagePlaceholder__F_lRl {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReclamationContent_ImageUploaded__lLzsR {
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ReclamationContent_RecapTags__Hyx3E {
  display: flex;
}
.ReclamationContent_RecapTags__Hyx3E p {
  padding: 0.5rem;
  background-color: #ee9834;
  border-radius: 16px;
  margin-right: 1rem;
  color: white;
  font-size: 0.8rem;
}
.ReclamationContent_RecapTitle__2-wdA {
  margin-top: 1rem;
}
.ReclamationContent_TitleArea__1MHSY {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 2.5rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_TitleArea__1MHSY::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapDescription__3kfuX {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionArea__2wtXE {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 7rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_DescriptionArea__2wtXE::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapImagesRow__78lR1 {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

/* large tablets & laptops */
@media screen and (min-width: 401px) {
  .ReclamationContent_RadioElementTwo__3VaT9 {
    margin-top: 1rem;
    padding-left: 2rem;
    flex: 1 0 32%;
  }
}

.CardAdminReclamation_Card__2aYtS {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  border: 3px solid #3b4578;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
}
.CardAdminReclamation_SelectedCard__25xAO {
  border: 2px solid #e57373;
}

.CardAdminReclamation_Thumbnail__1Dc0E {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.CardAdminReclamation_Thumbnail__1Dc0E img {
  border-radius: 14px;
  width: 100%;
}

.CardAdminReclamation_Main__1mGxK {
  margin: 0.5em 0;
  min-width: 11rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CardAdminReclamation_Titre__13GwD {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.CardAdminReclamation_Titre__13GwD span {
  font-weight: 700;
}

.CardAdminReclamation_Description__1Ev6Y {
  margin-right: 4em;
  font-size: 0.6rem;
}

.CardAdminReclamation_Icon__2IJUm {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.CardAdminReclamation_TagContainer__2O_Un {
  font-size: 0.6rem;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.CardAdminReclamation_Tag__3gKz4 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.CardAdminReclamation_Tag__3gKz4 p {
  padding: 0 0.2rem;
}

.CardAdminReclamation_Notification__1XZX2 {
  position: absolute;
  top: -1.2rem;
  left: 1rem;
  padding: 0.3rem;
  border-radius: 100px;
  background-color: #fcb34a;
}

.ModalUserReclamation_ModalCreateReclamation__3PXla {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ModalUserReclamation_ModalCreateReclamation__3PXla::-webkit-scrollbar {
  display: none;
}

.ModalUserReclamation_ModalContent__doRq9 {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: #f3f2f0;
  padding: 2rem;
}

.ModalUserReclamation_ModalTitle__1UxU1 {
  margin-top: -0.5rem;
  margin-bottom: 1.7rem;
  font-size: 0.9rem;
  font-weight: 700;
  color: #3b4578;
  display: flex;
  justify-content: space-between;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ModalUserReclamation_ModalContent__doRq9 {
    position: relative;
    border-radius: 10px;
    width: 30rem;
    height: 90%;
    max-height: 50rem;
    outline: none;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.ReclamationContent_IntroImage__g8-jg {
  margin: auto;
  margin-top: 4rem;
}

.ReclamationContent_Buttons__wmgG2 {
  bottom: 2rem;
  right: 2rem;
  left: 2rem;
  display: flex;
  justify-content: space-between;
}

.ReclamationContent_QuestionTitle__2TRPu {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.ReclamationContent_QuestionTitle__2TRPu p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.ReclamationContent_RadioFormTwo__lPIKa {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: space-between;
}

.ReclamationContent_RadioElementTwo__3JRnW {
  margin-top: 1rem;
  /*flex: 1 0 32%; */
}

.ReclamationContent_RadioElementTwo__3JRnW input[type="radio"] {
  display: none;
}

.ReclamationContent_RadioElementTwo__3JRnW input[type="radio"] + label + p {
  text-align: left;
  font-size: 0.8rem;
  width: 5rem;
}

.ReclamationContent_RadioElementTwo__3JRnW input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 5rem;
  width: 5rem;
  display: inline-block;
  position: relative;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.ReclamationContent_RadioElementTwo__3JRnW input[type="radio"] + label:hover {
  cursor: pointer;
}

.ReclamationContent_RadioElementTwo__3JRnW input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.ReclamationContent_RadioElementTwo__3JRnW input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.ReclamationContent_RadioElementTwo__3JRnW input[type="radio"]:checked + label i {
  display: inline-block;
}
.ReclamationContent_RadioElementTwo__3JRnW input[type="radio"]:checked + label + p {
  font-weight: bold;
}

.ReclamationContent_TitleField__2FxDK {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionField__b_xdk {
  margin-top: 1rem;
}

.ReclamationContent_ImagesRow__2jdRh {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.ReclamationContent_ImagePlaceholder__3u4p4 {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReclamationContent_ImageUploaded__15qB4 {
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.ReclamationContent_RecapTags__13j3P {
  display: flex;
}
.ReclamationContent_RecapTags__13j3P p {
  padding: 0.5rem;
  background-color: #ee9834;
  border-radius: 16px;
  margin-right: 1rem;
  color: white;
  font-size: 0.8rem;
}
.ReclamationContent_RecapTitle__2_12s {
  margin-top: 1rem;
}
.ReclamationContent_TitleArea__YYgrN {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 2.5rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_TitleArea__YYgrN::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapDescription__28zef {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionArea__2dgYw {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 5rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_DescriptionArea__2dgYw::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapImagesRow__2BGwO {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

/* large tablets & laptops */
@media screen and (min-width: 401px) {
  .ReclamationContent_RadioElementTwo__3JRnW {
    margin-top: 1rem;
    padding-left: 2rem;
    flex: 1 0 32%;
  }
}

.Footer_Footer__24SEK {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Footer_LogoContainer__QVV9R {
  width: 15em;
  margin: 1em 0;
}
.Footer_LogoContainer__QVV9R img {
  width: 100%;
}
.Footer_LegalLinks__3LnJ0 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.5rem;
}
.Footer_LegalLinks__3LnJ0 p {
  cursor: pointer;
  margin: 0.5rem;
  font-size: 0.8rem;
  color: #828282;
  padding: 0.8rem 1rem;
  background-color: #f3f2f0;
  border-radius: 10px;
}
.Footer_Copyrights__1jpCa {
  margin: 0.5rem 0;
  color: #5b5855;
  text-align: center;
  font-size: 0.8rem;
}
.Footer_Copyrights__1jpCa span {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 900;
  font-family: "Nunito Sans", sans-serif;
}
.Footer_ImmobiliereLogo__yE80z {
  width: 12rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.PDC_CGUTitle__16rGt {
  text-align: center;
}

.PDC_CGUWrapper__3Ya0T {
  margin-top: 20px;
  height: 50vh;
  overflow-y: scroll;
}

.PDC_Article__punoU {
  margin-bottom: 1em;
}

.PDC_ArticleTitle__1ozAQ {
  margin-bottom: 0.5em;
}
.PDC_ArticleSubtitle__2OKGT {
  margin-bottom: 0.5em;
}
.PDC_ArticleContent__2gc0M {
  margin-bottom: 0.5em;
}
.PDC_ArticleContent__2gc0M p {
  margin-bottom: 0.3em;
}

.CardModal_CardModal__1HSt1 {
  position: fixed;
  z-index: 500;
  background: white;
  overflow-y: scroll;
  /* border-radius: 9px; */
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .CardModal_CardModal__1HSt1 {
    position: static;
    overflow-y: hidden;
    background-color: #f3f2f0;
    width: auto;
    height: auto;
    padding: 2em;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1400px) {
  .CardModal_CardModal__1HSt1 {
    padding: 0;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
      0px 8px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
  }
}

.NonConcertableLayoutOne_Card__1EbtU {
  background-color: white;
  outline: none;
  overflow: scroll;
}

.NonConcertableLayoutOne_CardHeader__3fwwO {
  display: flex;
  top: 0;
  left: 0;
  margin: 2rem 2.5rem;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.NonConcertableLayoutOne_CardTitle__3Xz-Y h3 {
  margin-top: 1em;
  font-size: 1.5rem;
  color: #292f33;
  font-weight: bold;
}
.NonConcertableLayoutOne_CardTitle__3Xz-Y h4 {
  font-size: 1.3rem;
  color: #5b5855;
  font-weight: normal;
}

.NonConcertableLayoutOne_VideoPreview__m3Div {
  /* margin: 0.5em auto;
  width: 80%;
  max-width: 20em; */
  margin: 1em auto;
  display: flex;
  justify-content: center;
}

.NonConcertableLayoutOne_Description__2elVs {
  margin: 0.5rem 0;
  color: #898786;
  font-size: 0.9rem;
}

.NonConcertableLayoutOne_DescriptionPlus__2zxQj {
  background-color: #e0e0e0;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  color: #898786;
  font-size: 0.9rem;
  margin-top: 1.5rem;
}
.NonConcertableLayoutOne_DescriptionPlus__2zxQj span {
  color: #3d9dc7;
  font-weight: 900;
}

.NonConcertableLayoutOne_CardContent__2_d0k {
  margin-top: 2rem;
  padding: 2rem 2.5em;
  background-color: #f3f2f0;
}

.NonConcertableLayoutOne_Emoji__2IKJ2 {
  font-size: 1.8rem;
}

.NonConcertableLayoutOne_QuestionsHeader__zrHdl {
  color: #3b4578;
  margin-top: 0.7em;
  margin-bottom: 1.5rem;
}

.NonConcertableLayoutOne_QuestionTitle__1a5_k {
  margin-top: 0.5em;
  font-size: 1rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.NonConcertableLayoutOne_QuestionTitle__1a5_k p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.NonConcertableLayoutOne_CommentSection__3TnhN {
  margin-top: 2rem;
  text-align: center;
}

.NonConcertableLayoutOne_Buttons__1jQ7O {
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
}

.NonConcertableLayoutOne_NCSection__3UsQu {
  margin-top: 1.1em;
}
.NonConcertableLayoutOne_MapImage__20iDM {
  margin-top: 0.85em;
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14); */
  /* border-radius: 10px; */
}

.NonConcertableLayoutOne_Notices__3RUCQ {
  margin: 2em 0;
  font-size: 0.6rem;
}

/* Desktops */
@media screen and (min-width: 910px) {
  .NonConcertableLayoutOne_Card__1EbtU {
    padding: 0;
    border-radius: 10px;
    width: 80%;
    height: 90%;
    max-width: 50rem;
    max-height: 40rem;
    outline: none;
    display: flex;
  }
  .NonConcertableLayoutOne_Card__1EbtU::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .NonConcertableLayoutOne_Card__1EbtU {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .NonConcertableLayoutOne_VideoPreview__m3Div {
    margin-top: 3rem;
  }

  .NonConcertableLayoutOne_LeftSection__3f7ts {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .NonConcertableLayoutOne_RightSection__2r2JA {
    background-color: #f3f2f0;
    width: 50%;
    height: 100%;
    position: relative;
  }

  .NonConcertableLayoutOne_DescriptionPlus__2zxQj {
    margin-top: 0;
  }

  .NonConcertableLayoutOne_CardContent__2_d0k {
    height: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.Switch_switch__1e_ZD {
  position: relative;
  display: inline-block;
  width: 220px;
  height: 60px;
}

.Switch_switch__1e_ZD input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Switch_slider__28hSH {
  position: absolute;
  cursor: pointer;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  box-shadow: 0px 0px 55px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.Switch_slider__28hSH:before {
  position: absolute;
  content: "";

  width: 110px;
  height: 59px;
  left: 0px;
  top: 0px;

  background: #e2e2e2;
  box-shadow: inset 0px -7px 0px #bebebe;
  border-radius: 20px;

  -webkit-transition: 0.4s;

  transition: 0.4s;
}

input:checked + .Switch_slider__28hSH:before {
  background: #f5a623;
  box-shadow: inset 0px -7px 0px #d28e1e;
  border-radius: 20px;
  -webkit-transform: translateX(110px);
          transform: translateX(110px);
}

input:checked + .Switch_off__2zFwW {
  color: #ffffff;
}

.concertableLayoutOne_Card__1lglO {
  background-color: white;
  outline: none;
  overflow: scroll;
}

.concertableLayoutOne_CardHeader__o7oVo {
  display: flex;
  top: 0;
  left: 0;
  margin: 2rem 2.5rem;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.concertableLayoutOne_CardTitle__2AalV h3 {
  margin-top: 1em;
  font-size: 1.5rem;
  color: #292f33;
  font-weight: bold;
}
.concertableLayoutOne_CardTitle__2AalV h4 {
  font-size: 1.3rem;
  color: #5b5855;
  font-weight: normal;
}

.concertableLayoutOne_ImagePreview__1QcR2 {
  margin: 0.5em auto;
  width: 80%;
  max-width: 20em;
}
.concertableLayoutOne_ImagePreview__1QcR2 img {
  width: 100%;
}

.concertableLayoutOne_Description__1LH4s {
  margin: 0.5rem 2.5rem;
  color: #898786;
  font-size: 0.9rem;
}

.concertableLayoutOne_CardContent__Yr0PT {
  margin-top: 2rem;
  padding: 2rem 2.5em;
  background-color: #f3f2f0;
}

.concertableLayoutOne_Emoji__18ZcS {
  font-size: 1.8rem;
}

.concertableLayoutOne_QuestionsHeader__11oQO {
  color: #3b4578;
  margin-top: 0.7em;
  margin-bottom: 1.5rem;
}

.concertableLayoutOne_QuestionTitle__incRG {
  margin-top: 0.5em;
  font-size: 1rem;
  display: flex;
  font-weight: 900;
  /* justify-content: center; */
  align-items: center;
}
.concertableLayoutOne_QuestionTitle__incRG p {
  color: #2a2f39;
  margin-left: 0.4em;
}

/* ============================== radio form */
.concertableLayoutOne_RadioForm__3nNEG {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.concertableLayoutOne_RadioElement__jnOpd {
  margin: 1em 0.8em;
}

.concertableLayoutOne_RadioElement__jnOpd label {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.concertableLayoutOne_RadioElement__jnOpd p {
  text-align: center;
  margin-top: 0.5em;
  font-size: 0.85em;
}

.concertableLayoutOne_RadioElement__jnOpd img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 5em;
  /* border-radius: 50%; */
  /* box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.14); */
}

.concertableLayoutOne_RadioElement__jnOpd input {
  display: none;
}

.concertableLayoutOne_RadioElement__jnOpd input[type="radio"]:checked + label img {
  border: 3px solid #e31869;
}
.concertableLayoutOne_RadioElement__jnOpd input[type="radio"]:checked + label p {
  font-weight: bold;
}

/* TESTING ====================================================================== */
/* .RadioForm .RadioElementTwo {
  margin: 10px 5px 5px 0;
  display: inline-block;
} */

.concertableLayoutOne_RadioFormTwo__1kqTH {
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: left; */
}

.concertableLayoutOne_RadioElementTwo__3DdNk {
  margin: 1em 1em;
}

.concertableLayoutOne_RadioElementTwo__3DdNk input[type="radio"] {
  display: none;
}

.concertableLayoutOne_RadioElementTwo__3DdNk input[type="radio"] + label + p {
  text-align: center;
  width: 8em;
}

.concertableLayoutOne_RadioElementTwo__3DdNk input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 8em;
  width: 8em;
  display: inline-block;
  position: relative;
  border: 4px solid rgb(196, 196, 196);
  border-radius: 5%;
}

.concertableLayoutOne_RadioElementTwo__3DdNk input[type="radio"] + label:hover {
  cursor: pointer;
}

.concertableLayoutOne_RadioElementTwo__3DdNk input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.concertableLayoutOne_RadioElementTwo__3DdNk input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.concertableLayoutOne_RadioElementTwo__3DdNk input[type="radio"]:checked + label i {
  display: inline-block;
}
.concertableLayoutOne_RadioElementTwo__3DdNk input[type="radio"]:checked + label + p {
  font-weight: bold;
}

/* ============ END RADIO FORM */

.concertableLayoutOne_CommentSection__1uWhM {
  margin-top: 2rem;
  text-align: center;
}

.concertableLayoutOne_Buttons__1all- {
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
}

.concertableLayoutOne_MUIForm__OEy-X {
  margin-left: 2em;
}

.concertableLayoutOne_NCSection__3ugA_ {
  margin-top: 1.1em;
}
.concertableLayoutOne_MapImage__1X8YF {
  margin-top: 0.85em;
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14); */
  /* border-radius: 10px; */
}

.concertableLayoutOne_Notices__UQDwZ {
  margin: 2em 0;
  font-size: 0.6rem;
}

/* Desktops */
@media screen and (min-width: 910px) {
  .concertableLayoutOne_Card__1lglO {
    padding: 0;
    border-radius: 10px;
    width: 80%;
    height: 90%;
    outline: none;
    display: flex;
  }
  .concertableLayoutOne_Card__1lglO::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .concertableLayoutOne_Card__1lglO {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .concertableLayoutOne_Description__1LH4s {
  }

  .concertableLayoutOne_LeftSection__1zg6V {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .concertableLayoutOne_RightSection___kY3x {
    background-color: #f3f2f0;
    width: 50%;
    height: 100%;
    position: relative;
  }

  .concertableLayoutOne_CardContent__Yr0PT {
    height: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.ProfilePage_HeaderImageWrapper__Brtr6 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.ProfilePage_HeaderImageWrapper__Brtr6 img {
  width: 80%;
}

.ProfilePage_ProfilePage__Sgcjl h1 {
  margin: 1rem 2rem;
}
.ProfilePage_ProfilePage__Sgcjl h3 {
  margin: 1rem 0 1rem 2rem;
}
.ProfilePage_SubmitButton__3TJWP {
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.OvalButton_OvalButton__1QGfo {
  display: flex;
  margin: 0 auto;
  padding: 20px 40px;
  border: 0;
  outline: 0;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 41px;
}

.OvalButton_OvalButton__1QGfo:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.Resultats_Main__2ynqH {
  padding: 0 1em;
  margin-bottom: 2em;
}

.Resultats_Title__3jvVS {
  font-size: 0.9rem;
  margin-bottom: 2em;
}
.Resultats_Title__3jvVS span {
  font-size: 1.5rem;
  font-weight: bold;
}
.Resultats_StatsIllustration__2Ksqd {
  margin-top: 2em;
}
.Resultats_StatsContent__3Cd5y {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.Resultats_StatsContent__3Cd5y h3 {
  font-size: 1.4rem;
  font-weight: normal;
}
.Resultats_StatsContent__3Cd5y p {
  font-size: 2rem;
  font-weight: bold;
}
.Resultats_Illustration__1bF4E {
  margin: 1em auto;
  width: 80%;
  max-width: 25em;
  display: flex;
  justify-content: center;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Resultats_Resultats__3Hmz_ {
    margin: 0 2em;
    margin-top: 1em;
  }
}
/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Resultats_Resultats__3Hmz_ {
    margin: 0 10em;
  }
}

.PageOne_SectionTitle__3fAIn {
  text-align: center;
}
.PageOne_SectionTitle__3fAIn .PageOne_Emoji__37Qkr {
  font-size: 2.8em;
}
.PageOne_SectionTitle__3fAIn h3 {
  font-size: 1.5em;
}
.PageOne_SectionSubtitle__29dk2 {
  font-weight: bold;
  font-size: 0.8em;
  margin: 0.8em 2em;
}

.PageOne_EmojiText__3PYpf {
  display: flex;
  margin: 0 2em;
}
.PageOne_EmojiText__3PYpf .PageOne_Emoji__37Qkr {
  font-size: 1.2em;
}

.PageOne_EmojiText__3PYpf p {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-top: 0.3em;
}

.PageOne_ParticiperButton__2rNmh {
  text-align: center;
  margin: 1.5em 0;
}

.PageOne_Illustration__1sQkJ {
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 2em auto;
}

/* Caroussel */
.PageOne_CarouselSection__2jHaM {
  margin: 30px 0;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.PageOne_CarouselSection__2jHaM::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.PageOne_CarouselItem__3EzHC {
  flex: 0 0 auto;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 30px;
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}
.PageOne_CarouselItem__3EzHC:first-child {
  margin-left: 40px;
}
.PageOne_CourselItem__1YuRq:last-child {
  margin-right: 20px;
}

.PageOne_GrandesEtapes__2XySN {
  display: flex;
  justify-content: center;
}
.PageOne_GrandesEtapes__2XySN img {
  width: 80%;
  max-width: 33em;
  margin-bottom: -3em;
}
.PageOne_HeroImage__RsS-Y {
  width: 100%;
  margin-bottom: 1.5em;
  z-index: -1;
}
.PageOne_HeroImage__RsS-Y img {
  width: 100%;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .PageOne_HeroImage__RsS-Y {
    width: 40em;
    margin: 0 auto;
    margin-bottom: 3em;
  }
  .PageOne_HeroImage__RsS-Y img {
    border-radius: 8px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
      0px 2px 4px rgba(0, 0, 0, 0.14);
  }
  .PageOne_Section__2Oq_u {
    margin: 0 5.5em;
  }
  .PageOne_SectionTitle__3fAIn {
    display: flex;
    align-items: flex-end;
  }
  .PageOne_SectionSubtitle__29dk2 {
    font-weight: bold;
    font-size: 0.9em;
    margin: 0.8em 0;
  }
  .PageOne_ContactForm__78-ph {
    margin: 0 auto;
    max-width: 40em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .PageOne_HeroImage__RsS-Y {
    width: 50em;
    margin: 0 auto;
    margin-bottom: 4em;
  }
  .PageOne_Section__2Oq_u {
    margin: 0 20em;
  }
  .PageOne_Illustration__1sQkJ {
    width: 25em;
    height: 25em;
  }
}

.ContactForm_ContactForm__F1m-8 {
  margin: 1.5em 0.3em;
}
.ContactForm_ContactFormTitle__1rZwg {
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}
.ContactForm_Emoji__yuqpV {
  font-size: 1.5rem;
}
.ContactForm_ContactFormFields__30CF8 {
  margin: 2em;
  display: flex;
  flex-direction: column;
}
.ContactForm_Button__20yD5 {
  text-align: end;
}
.ContactForm_Success__3FDO1 {
  margin-left: 0.2em;
  margin-bottom: 1em;
  color: green;
  font-size: 0.8rem;
}
.ContactForm_Error__3gjlf {
  margin-bottom: 1em;
  margin-left: 0.2em;
  color: red;
  font-size: 0.8rem;
}

.Separator_Separator__JsmVg {
  margin: 0 auto;
  height: 4px;
  width: 90%;
  max-width: 60em;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: inset 1px 3px 5px #a6abbd, inset 7px 4px 14px #fafbff;
  border-radius: 4px;
}

/* .Content {
  margin-top: 56px;
} */

/* Small tablets */

/* Large tablets & laptops */
@media (min-width: 960px) {
  .Layout_Content__3y0pD {
    margin-top: 0px;
  }
}

.Toolbar_Toolbar__1483J {
  height: 57px;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #ebecf0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
}

/* .Logo {
  height: 60%;
} */
.Toolbar_Logo__3lQlt h3 {
  text-transform: uppercase;
  font-size: 1.8rem;
  color: #5b5855;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 619px) {
  .Toolbar_DesktopOnly__2I0fr {
    display: none;
  }
}

/* Small tablets */
@media (min-width: 620px) {
  .Toolbar_Toolbar__1483J {
    /* box-shadow: none; */
    height: 70px;
    padding: 0 40px;
    /* position: relative; */
    background-color: white;
  }
  .Toolbar_Logo__3lQlt h3 {
    font-size: 1.8rem;
  }
}

/* Large Tablets & laptops */
@media screen and (min-width: 960px) {
  .Toolbar_Toolbar__1483J {
    /* height: 100px; */
    padding: 10px 80px;
  }
  .Toolbar_Logo__3lQlt h3 {
    font-size: 2rem;
  }
}

/* Large Tablets & laptops */
@media screen and (min-width: 1200px) {
  .Toolbar_Toolbar__1483J {
    padding: 10px 100px;
  }
}

.SideDrawer_SideDrawer__33yGk {
  position: fixed;
  width: 280px;
  max-width: 60%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 200;
  background-color: #ebecf0;
  padding: 32px 16px;
  box-sizing: border-box;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

@media (min-width: 620px) {
  .SideDrawer_SideDrawer__33yGk {
    display: none;
  }
}

.SideDrawer_Open__2VMeG {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.SideDrawer_Close__1ydaE {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.SideDrawer_Logo__3umG6 {
  height: 11%;
  margin-bottom: 32px;
}

.NavigationItems_NavigationItems__3ONBA {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
}

/* Small tablets */
@media screen and (min-width: 620px) {
  .NavigationItems_NavigationItems__3ONBA {
    flex-flow: row;
    width: 50vw;
  }

  .NavigationItems_Participer__3WwYJ {
    margin-left: 25px;
  }
}

/* Large tablets & laptops */
@media (min-width: 960px) {
  .NavigationItems_NavigationItems__3ONBA {
    width: 45vw;
  }
}

.NavigationItem_NavigationItem__3mq5_ {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.NavigationItem_NavigationItem__3mq5_ a {
  color: #2a2f39;
  padding: 10px 20px;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 7px;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.NavigationItem_NavigationItem__3mq5_ a:hover,
.NavigationItem_NavigationItem__3mq5_ a:active,
.NavigationItem_NavigationItem__3mq5_ a.NavigationItem_active__2q8Q1 {
  color: #e6404d;
  font-weight: bold;
  box-shadow: inset 5px 5px 10px #a6abbd, inset -5px -5px 10px #fafbff;
}

/* Small tablets */
@media (min-width: 620px) {
  .NavigationItem_NavigationItem__3mq5_ {
    margin: 10px 10px;
    padding: 10 30px;
  }
  .NavigationItem_NavigationItem__3mq5_ a {
    border: none;
    box-shadow: none;
    padding: 10px 0;
  }
  .NavigationItem_NavigationItem__3mq5_ a:hover,
  .NavigationItem_NavigationItem__3mq5_ a:active,
  .NavigationItem_NavigationItem__3mq5_ a.NavigationItem_active__2q8Q1 {
    box-shadow: none;
    font-weight: bold;
    color: #e6404d;
  }
}

.LongButton_LongButton__1uuZH {
  font-family: inherit;
  font-size: 0.9em;
  font-weight: bold;
  padding: 5px 15px;
  outline: none;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 4px;
  cursor: pointer;
}
.LongButton_LongButton__1uuZH:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

